import Head from 'next/head';
import { ReactNode } from 'react';

const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL || 'https://wolfia.com';

interface Props {
  title?: string;
  description?: string;
  keywords?: string;
  favicon?: string;
  ogImage?: string;
  ogUrl?: string;
  canonicalUrl?: string;
  noIndex?: boolean;
  children?: ReactNode;
}

const DEFAULT_BRAND_NAME = 'Wolfia';
const DEFAULT_TAGLINE = 'AI Agent for Sales & Security Teams';
const DEFAULT_TITLE = `${DEFAULT_BRAND_NAME} | ${DEFAULT_TAGLINE}`;
const DEFAULT_DESCRIPTION = 'Wolfia provides instant expert answers, automates security questionnaires, and completes RFPs to help teams move deals forward faster.';
const DEFAULT_OG_IMAGE_PATH = '/images/meta/og-image.png';

export function Meta({
  title,
  description,
  keywords,
  favicon,
  ogImage,
  ogUrl,
  canonicalUrl,
  noIndex,
  children
}: Props) {
  const pageTitle = title ? `${title} | ${DEFAULT_BRAND_NAME}` : DEFAULT_TITLE;
  const pageDescription = description ?? DEFAULT_DESCRIPTION;
  const pageOgUrl = ogUrl ?? BASE_URL;

  const absoluteOgImageUrl = (() => {
    const imagePath = ogImage ?? DEFAULT_OG_IMAGE_PATH;
    if (imagePath.startsWith('http')) {
      return imagePath;
    }
    return `${BASE_URL}${imagePath.startsWith('/') ? '' : '/'}${imagePath}`;
  })();

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <meta name="description" content={pageDescription} />
      {keywords && <meta name="keywords" content={keywords}/>}

      {
        favicon ? (
          <link rel="icon" href={favicon}/>
        ) : (
          <>
            <link rel="icon" href="/favicon.ico" sizes="any"/>
            <link rel="icon" href="/images/meta/icon.svg" type="image/svg+xml"/>
            <link rel="apple-touch-icon" href="/images/meta/apple-touch-icon.png"/>
            <link rel="manifest" href="/site.webmanifest"/>
          </>
        )
      }

      {noIndex && <meta name="robots" content="noindex, follow" />}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

      <meta property="og:type" content="website"/>
      <meta property="og:url" content={pageOgUrl}/>
      <meta property="og:title" content={pageTitle}/>
      <meta property="og:description" content={pageDescription}/>
      <meta property="og:image" content={absoluteOgImageUrl}/>
      <meta property="og:image:width" content="1200"/>
      <meta property="og:image:height" content="630"/>
      <meta property="og:image:alt" content={`${pageTitle} Image`} />
      <meta property="og:site_name" content={DEFAULT_BRAND_NAME}/>
      <meta property="og:locale" content="en_US"/>

      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:url" content={pageOgUrl}/>
      <meta name="twitter:title" content={pageTitle}/>
      <meta name="twitter:description" content={pageDescription}/>
      <meta name="twitter:image" content={absoluteOgImageUrl}/>
      <meta name="twitter:image:alt" content={`${pageTitle} Image`} />
      <meta name="twitter:site" content="@wolfia_app"/>

      <link rel="preconnect" href="https://rsms.me/"/>
      <link rel="stylesheet" href="https://rsms.me/inter/inter.css"/>

      {children}
    </Head>
  );
}
