import * as React from "react";
import { cn } from "@/lib/utils";

interface KeyboardShortcutProps {
  shortcutKey: string;
  className?: string;
}

/**
 * A consistent visual representation of a keyboard shortcut key
 */
export const KeyboardShortcut = React.forwardRef<
  HTMLSpanElement,
  KeyboardShortcutProps
>(({ shortcutKey, className }, ref) => {
  // Handle complex shortcut combinations
  const keys = shortcutKey
    .split('+')
    .map((key) => key.trim())
    .filter(Boolean);

  return (
    <span
      ref={ref}
      className={cn(
        "inline-flex items-center",
        className
      )}
      aria-hidden="true"
    >
      {keys.map((key, index) => (
        <React.Fragment key={key}>
          <kbd className="whitespace-nowrap min-w-[1rem] h-4 px-1 inline-flex items-center justify-center rounded bg-gray-600 text-gray-200 text-2xs font-mono font-semibold uppercase">
            {key}
          </kbd>
          {index < keys.length - 1 && <span className="text-2xs text-gray-500">+</span>}
        </React.Fragment>
      ))}
    </span>
  );
});

KeyboardShortcut.displayName = "KeyboardShortcut";