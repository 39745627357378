import { keyframes, styled } from '@/stitches.config';
import React from 'react';

const shineAnimation = keyframes({
  '0%': { left: '-100%' },
  '100%': { left: '200%' }
});

const WolfiaLogo = () => (
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="shine-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" stopColor="rgba(255,255,255,0)" />
        <stop offset="50%" stopColor="rgba(255,255,255,0.3)" />
        <stop offset="100%" stopColor="rgba(255,255,255,0)" />
      </linearGradient>
      <clipPath id="logo-clip">
        <path d="M27.0073 38.7381C34.5968 35.8986 40 28.5803 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 25.358 2.10697 30.224 5.53757 33.8145C9.37691 30.9061 10.899 25.9395 11.4928 24.0021C11.5232 23.9027 11.5513 23.8111 11.5771 23.7283C10.6391 24.1241 5.60767 23.2381 4.7886 20.4304C9.94243 19.0148 12.7987 16.7823 14.8206 15.202C15.5387 14.6406 16.1517 14.1615 16.7249 13.8306C18.4745 12.8204 20.6712 13.1317 21.453 13.4336C22.805 12.6531 24.299 11.5198 25.6653 10.4835C27.6143 9.00506 29.3033 7.72392 29.9487 7.94546C32.4035 8.7881 31.9581 15.9701 30.7197 17.487C31.984 18.288 32.8279 19.6233 30.8365 20.846C30.7264 20.8475 30.6164 20.8565 30.5067 20.8726C30.6162 20.8625 30.7261 20.8535 30.8365 20.846C26.9349 22.7729 23.1867 31.7879 27.0073 38.7381Z" />
      </clipPath>
    </defs>
    <g clipPath="url(#logo-clip)">
      <path
        d="M27.0073 38.7381C34.5968 35.8986 40 28.5803 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 25.358 2.10697 30.224 5.53757 33.8145C9.37691 30.9061 10.899 25.9395 11.4928 24.0021C11.5232 23.9027 11.5513 23.8111 11.5771 23.7283C10.6391 24.1241 5.60767 23.2381 4.7886 20.4304C9.94243 19.0148 12.7987 16.7823 14.8206 15.202C15.5387 14.6406 16.1517 14.1615 16.7249 13.8306C18.4745 12.8204 20.6712 13.1317 21.453 13.4336C22.805 12.6531 24.299 11.5198 25.6653 10.4835C27.6143 9.00506 29.3033 7.72392 29.9487 7.94546C32.4035 8.7881 31.9581 15.9701 30.7197 17.487C31.984 18.288 32.8279 19.6233 30.8365 20.846C30.7264 20.8475 30.6164 20.8565 30.5067 20.8726C30.6162 20.8625 30.7261 20.8535 30.8365 20.846C26.9349 22.7729 23.1867 31.7879 27.0073 38.7381Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M23.5665 39.6833C21.8919 35.9711 20.4415 32.5592 22.2145 28.127C21.3692 28.1776 19.2145 29.1267 19.2145 29.1267C23.2145 23.1267 26.7145 21.1267 30.8365 20.8467C26.9349 22.7736 23.1867 31.7883 27.0073 38.7384C25.9033 39.1514 24.7531 39.4697 23.5665 39.6833Z"
        fill="currentColor"
      >
        <animate attributeName="opacity" values="0.2;0.4;0.2" dur="2s" repeatCount="indefinite" />
      </path>
    </g>
  </svg>
);

const SpinnerWrapper = styled('div', {
  display: 'inline-block',
  position: 'relative',
  '& svg': {
    display: 'block',
    width: '100%',
    height: '100%',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '-100%',
    width: '50%',
    height: '100%',
    background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,0) 100%)',
    animation: `${shineAnimation} 2s infinite`,
    transform: 'skewX(-20deg)',
  },
  defaultVariants: {
    size: 'medium',
  },
  variants: {
    size: {
      xsmall: {
        size: '10px',
      },
      small: {
        size: '16px',
      },
      medium: {
        size: '48px',
      },
      large: {
        size: '72px',
      },
    },
  },
});

export const Spinner = ({ size = 'medium' }) => (
  <SpinnerWrapper size={size}>
    <WolfiaLogo />
  </SpinnerWrapper>
);
